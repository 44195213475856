import type { UseChatHelpers } from "ai/react/dist";
import { RotateCcwIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";

import { cn } from "../lib/utils";
import {
  getRandomWelcomeMessageFromAppearance,
  useAppearance,
  useSession,
} from "./chat-provider";
import { Markdown } from "./markdown";

export function ChatActions({
  setMessages,
}: {
  setMessages: UseChatHelpers["setMessages"];
}) {
  const appearance = useAppearance();
  const { resetSession } = useSession();
  const posthog = usePostHog();
  return (
    <>
      <div
        className={cn(
          "bg-accent transition-all duration-300 ease-in-out",
          "flex cursor-pointer items-center justify-center rounded-full shadow-lg",
          "h-10 gap-2 px-4",
        )}
        onClick={() => {
          setMessages(getRandomWelcomeMessageFromAppearance(appearance));
          resetSession();
          posthog?.capture("chat_reset");
        }}
      >
        <Markdown className="text-accent-foreground" maxWidth={200}>
          {appearance.restartChat}
        </Markdown>
        <div
          className={cn("relative flex items-center justify-center", "h-5 w-5")}
        >
          <RotateCcwIcon
            className={cn(
              "absolute inset-0",
              "stroke-accent-foreground",
              "h-5 w-5",
            )}
          />
        </div>
      </div>
    </>
  );
}
