import type * as z from "zod";

import type { ApiFunction } from "@apeeling/shared/models/api";
import type { BootstrapModelType } from "@apeeling/shared/models/client";

import { useBootstrapConfig } from "../components/chat-provider";

export function useApi(config?: BootstrapModelType) {
  const conf = config ?? useBootstrapConfig();

  const wrappedFetch = async <In extends z.ZodType, Out extends z.ZodType>(
    f: (baseUrl: string, representativeId: string) => ApiFunction<In, Out>,
  ): Promise<z.infer<NonNullable<ReturnType<typeof f>["contract"]["out"]>>> => {
    if (conf.mode === "preview") {
      throw new Error("Cannot use API in preview mode");
    }

    const { method, body, contract, url } = f(
      conf.baseUri,
      conf.representativeId,
    );
    const inParsed = contract.in.parse(body) as In;

    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: inParsed ? JSON.stringify(inParsed) : null,
    });
    if (!response.ok) {
      throw new Error(
        `Request failed with status ${response.status}: ${response.statusText}`,
      );
    }

    const json = (await response.json()) as unknown;
    return contract.out.parse(json ?? undefined) as Out;
  };

  return { fetch: wrappedFetch };
}
