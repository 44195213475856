// Inspired by Chatbot-UI and modified to fit the needs of this project
// @see https://github.com/mckaywrigley/chatbot-ui/blob/main/components/Chat/ChatMessage.tsx

import React, { useEffect, useMemo, useState } from "react";
import type {
  MessageAnnotationItemModelType,
  MessageAnnotationItemToolCallResultRetrievalType,
  MessageAnnotationItemToolCallResultType,
  MessageAnnotationItemToolCallsModelType,
} from "@/../shared/models/client";
import { TooltipContent, TooltipTrigger } from "@radix-ui/react-tooltip";
import type { Message } from "ai";
import type { UseChatHelpers } from "ai/react/dist";
import Autoplay from "embla-carousel-autoplay";
import { AlertCircle, CheckIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";

import { time } from "@apeeling/shared/utils";

import { cn, getIconComponentFromAppearance } from "../lib/utils";
import { ChatMessageActions } from "./chat-message-actions";
import { useAppearance, useBootstrapConfig } from "./chat-provider";
import { Markdown } from "./markdown";
import { useDarkMode } from "./tailwind-provider";
import { Carousel, CarouselContent, CarouselItem } from "./ui/carousel";
import { CoolSpinner } from "./ui/icons";
import { Tooltip, TooltipProvider } from "./ui/tooltip";

export interface ChatMessageProps
  extends Pick<UseChatHelpers, "stop" | "isLoading" | "reload"> {
  message: Message;
  isLastMessage?: boolean;
  isFirstMessage?: boolean;
}
function Annotations({ data }: { data: MessageAnnotationItemModelType[] }) {
  const [calls, setCalls] = useState<
    Record<
      string,
      {
        idx: number;
        call: MessageAnnotationItemToolCallsModelType["calls"][0];
        result?: MessageAnnotationItemToolCallResultType;
      }
    >
  >({});
  const [index, setIndex] = useState(0);

  useEffect(() => {
    for (let i = index; i < data.length; i++) {
      const item = data[i]!;
      if (item.type == "tool_calls") {
        for (const call of item.calls) {
          setCalls((calls) => ({
            ...calls,
            [call.id]: { idx: i, call },
          }));
        }
      } else if (item.type == "tool_call_result") {
        setCalls((calls) => ({
          ...calls,
          [item.id]: { ...calls[item.id]!, result: item },
        }));
      }
    }
    setIndex(data.length);
  }, [data]);

  return (
    <>
      {Object.values(calls)
        .sort((a, b) => a.idx - b.idx)
        .map(({ call, result }, i) => {
          return (
            <div key={i} className="flex flex-col items-center gap-2">
              <ToolCall call={call} result={result} />
            </div>
          );
        })}
    </>
  );
}

const formatArguments = (args: string) => {
  if (!args) {
    return "";
  }
  const truncate = (str: string, n: number) =>
    str.length > n ? str.slice(0, n) + "..." : str;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  args = JSON.parse(args);
  const formatted = Object.entries(args)
    .map(([_key, value]) => `${truncate(value, 15)}`)
    .join(", ");
  return formatted.length > 0 ? ` (${truncate(formatted, 70)})` : "";
};

function ToolCall({
  call,
  result,
}: {
  call: MessageAnnotationItemToolCallsModelType["calls"][0];
  result?: MessageAnnotationItemToolCallResultType;
}) {
  const posthog = usePostHog();
  useEffect(() => {
    if (result && call) {
      posthog?.capture("tool_call", {
        tool_name: call.name,
        tool_type: call.type,
      });
    }
  }, [call, result]);

  return (
    <>
      <div className="flex justify-center gap-2">
        {result && (
          <CheckIcon className="text-primary" width={24} height={24} />
        )}
        {!result && (
          <CoolSpinner className="fill-primary" width={24} height={24} />
        )}
        <Markdown className="text-center text-sm">
          {`${call.description}${formatArguments(call.arguments)}`}
        </Markdown>
      </div>
    </>
  );
}

function RetrievalResult({
  source,
}: {
  source: MessageAnnotationItemToolCallResultRetrievalType["results"][0];
}) {
  const posthog = usePostHog();
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {((loading && !loaded) || loaded) && (
        <Tooltip>
          <TooltipTrigger>
            <a
              href={source.url}
              target="_blank"
              rel="noreferrer"
              className="bg-primary flex items-center gap-2 rounded-lg"
            >
              <img
                src={source.imageUrl!}
                alt={source.title}
                onLoad={() => {
                  setLoading(false);
                  setLoaded(true);
                }}
                onError={() => {
                  posthog?.capture("retrieval_image_load_error", {
                    url: source.url,
                  });

                  setLoading(false);
                }}
                className="max-h-20 rounded-lg"
              />
            </a>
          </TooltipTrigger>
          <TooltipContent>
            <div className="bg-background flex flex-col items-center justify-center gap-2 rounded-lg p-2">
              <a
                href={source.url}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
                onClick={() => {
                  posthog?.capture("retrieval_image_click", {
                    url: source.url,
                  });
                }}
              >
                {source.title}
              </a>
            </div>
          </TooltipContent>
        </Tooltip>
      )}
    </>
  );
}

function RetrievalResults({
  data,
  content,
}: {
  data?: MessageAnnotationItemToolCallResultRetrievalType[];
  content?: string;
}) {
  if (!data?.length) {
    return null;
  }
  const retrievalPlugin = React.useRef(Autoplay({ delay: 5000 }));
  const relevantData = useMemo(() => {
    return data
      .map((x) => x.results.map((y) => ({ ...y, overview: x.overview })))
      .flat()
      .filter(
        (x) =>
          x.imageUrl &&
          (x.overview || (!x.overview && content?.includes(x.url))),
      )
      .filter(
        (value, index, self) =>
          self.findIndex((t) => t.url === value.url) === index,
      );
  }, [data]);

  return (
    <>
      <div className="flex justify-center gap-2">
        <TooltipProvider>
          <Carousel
            plugins={[retrievalPlugin.current]}
            onMouseEnter={retrievalPlugin.current.stop}
            onMouseLeave={retrievalPlugin.current.reset}
            opts={{
              align: "start",
              loop: true,
            }}
          >
            <CarouselContent>
              {relevantData.map((source, i) => (
                <CarouselItem
                  key={i}
                  className={`basis-1/${Math.min(2, relevantData.length)} md:basis-1/${Math.min(3, relevantData.length)} xl:basis-1/${Math.min(4, relevantData.length)} flex justify-center`}
                >
                  <div key={`${i}`} className="mt-2">
                    <RetrievalResult source={source} />
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </TooltipProvider>
      </div>
    </>
  );
}

export function ChatMessage({
  message,
  stop,
  isLoading,
  reload,
  isLastMessage,
  isFirstMessage,
  ...props
}: ChatMessageProps) {
  const appearance = useAppearance();
  const sentDate =
    typeof message.createdAt === "string"
      ? new Date(message.createdAt)
      : message.createdAt;

  const hasError = useMemo(() => {
    return (
      (message.annotations as MessageAnnotationItemModelType[])?.find(
        (x) => x.type === "error",
      ) != null
    );
  }, [message.annotations]);

  const darkMode = useDarkMode();
  const config = useBootstrapConfig();
  return (
    <>
      {!message.annotations && !message.content && (
        <div className="flex items-center justify-center">
          <CoolSpinner className="fill-primary" width={24} height={24} />
        </div>
      )}
      {!hasError && message.annotations && (
        <Annotations
          data={message.annotations as MessageAnnotationItemModelType[]}
        />
      )}
      <div className="my-4" />
      {message.content && (
        <div className={cn("group relative mb-4 flex items-start")} {...props}>
          <div
            className={cn(
              "border-border flex h-10 w-10 shrink-0 select-none items-center justify-center rounded-md border shadow",
              message.role === "user"
                ? "bg-background text-primary"
                : "bg-primary text-primary-foreground",
            )}
          >
            {message.role === "user"
              ? getIconComponentFromAppearance({
                  appearance,
                  iconName: "user",
                  size: 5,
                  darkMode,
                })
              : getIconComponentFromAppearance({
                  appearance,
                  iconName: "assistant",
                  size: 5,
                  darkMode,
                })}
          </div>
          <div className="ml-4 flex-1 space-y-2 overflow-hidden px-1">
            <Markdown className="text-card-foreground">
              {message.content}
            </Markdown>

            <RetrievalResults
              content={message.content}
              data={(
                message.annotations as MessageAnnotationItemToolCallResultType[]
              )
                ?.filter(
                  (x) =>
                    x.type === "tool_call_result" &&
                    x.tool?.type === "retrieval",
                )
                .map(
                  (x) =>
                    // eslint-disable-next-line
                    x.tool as MessageAnnotationItemToolCallResultRetrievalType,
                )}
            />

            <div className="flex items-center gap-2">
              {(hasError || (config.mode == "preview" && config.showError)) && (
                <div className="flex items-center justify-center gap-1">
                  <AlertCircle className="text-destructive m-2" />
                  <Markdown className="m-2 text-center text-sm">
                    {appearance.errorOccured}
                  </Markdown>
                </div>
              )}
              <div className="flex-1" />
              <ChatMessageActions
                isLastMessage={isLastMessage}
                isFirstMessage={isFirstMessage}
                message={message}
                stop={stop}
                isLoading={isLoading}
                reload={reload}
              />
              <p className="text-card-foreground text-xs">
                {sentDate && time(sentDate)}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
