import type { Message } from "ai";
import type { UseChatHelpers } from "ai/react/dist";

import { ChatMessage } from "./chat-message";
import { Separator } from "./ui/separator";

export interface ChatList
  extends Pick<UseChatHelpers, "stop" | "isLoading" | "reload"> {
  messages: Message[];
}

export function ChatList({ messages, stop, isLoading, reload }: ChatList) {
  if (!messages.length) {
    return null;
  }

  return (
    <div className="relative mx-auto max-w-2xl overflow-auto">
      {messages.map((message, index) => (
        <div key={index}>
          <ChatMessage
            stop={stop}
            isLoading={isLoading}
            reload={reload}
            message={message}
            isFirstMessage={index === 0}
            isLastMessage={index === messages.length - 1}
          />
          {index < messages.length - 1 && <Separator className="my-4" />}
        </div>
      ))}
    </div>
  );
}
