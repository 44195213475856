import { TooltipProvider } from "@radix-ui/react-tooltip";
import type { UseChatHelpers } from "ai/react/dist";

import { cn } from "../lib/utils";
import { ChatHeader } from "./chat-header";
import { ChatList } from "./chat-list";
import { ChatPanel } from "./chat-panel";
import { useBootstrapConfig } from "./chat-provider";
import { FrequentlyAskedQuestions } from "./frequently-asked-questions";

export type ChatProps = React.ComponentProps<"div"> & UseChatHelpers;

export function Chat({
  className,
  append,
  input,
  isLoading,
  reload,
  messages,
  setInput,
}: ChatProps) {
  const config = useBootstrapConfig();

  return (
    <div className="bg-background flex max-h-[80dvh] flex-col overflow-hidden rounded-2xl shadow-lg transition-all duration-100">
      <TooltipProvider>
        <ChatHeader />
        <div className="no-scrollbar flex flex-col-reverse overflow-y-scroll">
          <div className={cn("px-4 pb-4 pt-4", className)}>
            <ChatList
              messages={messages}
              stop={stop}
              isLoading={isLoading}
              reload={reload}
            />
          </div>
        </div>

        {(messages.length == 1 || config.mode == "preview") && (
          <FrequentlyAskedQuestions append={append} />
        )}

        <ChatPanel
          isLoading={isLoading}
          append={append}
          input={input}
          setInput={setInput}
        />
      </TooltipProvider>
    </div>
  );
}
