import { Skeleton } from "./ui/skeleton";

export function ChatSkeleton() {
  return (
    <div>
      <div className="flex items-center space-x-4 p-4">
        <Skeleton className="h-8 w-8 rounded-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-[225px] sm:w-[350px]" />
          <Skeleton className="h-4 w-[200px] sm:w-[300px]" />
        </div>
      </div>
      <div className="flex items-center space-x-4 p-4">
        <Skeleton className="h-8 w-8 rounded-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-[200px] sm:w-[300px]" />
          <Skeleton className="h-4 w-[225px]  sm:w-[350px]" />
        </div>
      </div>
      <div className="flex items-center space-x-4 p-4">
        <Skeleton className="h-16 w-[300px] sm:w-[450px]" />
      </div>
    </div>
  );
}
