import { getIconComponentFromAppearance } from "../lib/utils";
import { useAppearance } from "./chat-provider";
import { Markdown } from "./markdown";
import { useDarkMode } from "./tailwind-provider";

export function ChatHeader() {
  const appearance = useAppearance();
  const darkMode = useDarkMode();
  return (
    <div className="bg-muted p-4">
      <div className="flex items-center gap-4">
        <div className="bg-primary flex h-14 w-14 items-center justify-center rounded-full">
          {getIconComponentFromAppearance({
            appearance,
            size: 8,
            iconName: "header",
            darkMode,
          })}
        </div>
        <div className="flex flex-col justify-center">
          <Markdown className="text-foreground text-xl">
            {appearance.displayName}
          </Markdown>
          {appearance.description && (
            <Markdown className="text-foreground">
              {appearance.description}
            </Markdown>
          )}
        </div>
      </div>
    </div>
  );
}
