import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { BaseTheme, Twind } from "@twind/core";
import { cssom, defineConfig, observe, twind } from "@twind/core";
import presetAutoprefix from "@twind/preset-autoprefix";
import presetExt from "@twind/preset-ext";
import type { TailwindTheme } from "@twind/preset-tailwind";
import presetTailwind from "@twind/preset-tailwind";
import type { TypographyTheme } from "@twind/preset-typography";
import presetTypography from "@twind/preset-typography";
import root, { useShadowRoot } from "react-shadow";

import type { AppearanceModelType } from "@apeeling/shared/models/client";

import { useAppearance, useBootstrapConfig, useConfig } from "./chat-provider";

const ShadowDomRootDiv = root.div!;

const TwindContext = createContext<
  | {
      tw: Twind<
        BaseTheme &
          TailwindTheme &
          TypographyTheme & {
            container: {
              center: boolean;
              padding: string;
              screens: {
                "2xl": string;
              };
            };
            extend: never;
          },
        CSSStyleSheet
      >;
    }
  | undefined
>(undefined);

export interface TwindRootProps {
  children: ReactNode;
}

const DarkModeContext = createContext<boolean>(false);
export const useDarkMode = () => useContext(DarkModeContext);

function getCssVariables(appearance: AppearanceModelType) {
  const {
    variables: { dark, ...light },
  } = appearance;
  return {
    ":host": {
      "--aa-background": light.background,
      "--aa-foreground": light.foreground,
      "--aa-card": light.card,
      "--aa-card-foreground": light.cardForeground,
      "--aa-popover": light.popover,
      "--aa-popover-foreground": light.popoverForeground,
      "--aa-primary": light.primary,
      "--aa-primary-foreground": light.primaryForeground,
      "--aa-secondary": light.secondary,
      "--aa-secondary-foreground": light.secondaryForeground,
      "--aa-muted": light.muted,
      "--aa-muted-foreground": light.mutedForeground,
      "--aa-accent": light.accent,
      "--aa-accent-foreground": light.accentForeground,
      "--aa-destructive": light.destructive,
      "--aa-destructive-foreground": light.destructiveForeground,
      "--aa-border": light.border,
      "--aa-input": light.input,
      "--aa-ring": light.ring,
      "--aa-radius": light.radius,
    },
    ":host(.dark)": {
      "--aa-background": dark.background,
      "--aa-foreground": dark.foreground,
      "--aa-card": dark.card,
      "--aa-card-foreground": dark.cardForeground,
      "--aa-popover": dark.popover,
      "--aa-popover-foreground": dark.popoverForeground,
      "--aa-primary": dark.primary,
      "--aa-primary-foreground": dark.primaryForeground,
      "--aa-secondary": dark.secondary,
      "--aa-secondary-foreground": dark.secondaryForeground,
      "--aa-muted": dark.muted,
      "--aa-muted-foreground": dark.mutedForeground,
      "--aa-accent": dark.accent,
      "--aa-accent-foreground": dark.accentForeground,
      "--aa-destructive": dark.destructive,
      "--aa-destructive-foreground": dark.destructiveForeground,
      "--aa-border": dark.border,
      "--aa-input": dark.input,
      "--aa-ring": dark.ring,
    },
  };
}
function DynamicStyle() {
  const config = useConfig();
  const variables = useMemo(() => getCssVariables(config.appearance), [config]);

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
          ${Object.entries(variables)
            .map(([key, value]) => {
              return `${key} { ${Object.entries(value)
                .map(([key, value]) => {
                  return `${key}: ${value} !important;`;
                })
                .join("\n")} }`;
            })
            .join("\n")}
        `,
      }}
    ></style>
  );
}

function TwindProviderInner({ children }: { children: ReactNode }) {
  const sheet = useMemo(() => cssom(new CSSStyleSheet()), []);
  const root = useShadowRoot();
  const [darkMode, setDarkMode] = useState(false);
  const config = useBootstrapConfig();
  const appearance = useAppearance();

  const tw = useMemo(() => {
    const tw = twind(
      defineConfig({
        presets: [
          presetAutoprefix(),
          presetTailwind(),
          presetTypography(),
          presetExt(),
        ],
        darkMode: "class",
        theme: {
          container: {
            center: true,
            padding: "2rem",
            screens: {
              "2xl": "1400px",
            },
          },
          extend: {
            colors: {
              border: "hsl(var(--aa-border)) !important",
              input: "hsl(var(--aa-input)) !important",
              ring: "hsl(var(--aa-ring)) !important",
              background: "hsl(var(--aa-background)) !important",
              foreground: "hsl(var(--aa-foreground)) !important",
              primary: {
                DEFAULT: "hsl(var(--aa-primary)) !important",
                foreground: "hsl(var(--aa-primary-foreground)) !important",
              },
              secondary: {
                DEFAULT: "hsl(var(--aa-secondary)) !important",
                foreground: "hsl(var(--aa-secondary-foreground)) !important",
              },
              destructive: {
                DEFAULT: "hsl(var(--aa-destructive)) !important",
                foreground: "hsl(var(--aa-destructive-foreground)) !important",
              },
              muted: {
                DEFAULT: "hsl(var(--aa-muted)) !important",
                foreground: "hsl(var(--aa-muted-foreground)) !important",
              },
              accent: {
                DEFAULT: "hsl(var(--aa-accent)) !important",
                foreground: "hsl(var(--aa-accent-foreground)) !important",
              },
              popover: {
                DEFAULT: "hsl(var(--aa-popover)) !important",
                foreground: "hsl(var(--aa-popover-foreground)) !important",
              },
              card: {
                DEFAULT: "hsl(var(--aa-card))",
                foreground: "hsl(var(--aa-card-foreground)) !important",
              },
            },
            borderRadius: {
              lg: "var(--aa-radius)",
              md: "calc(var(--aa-radius) - 2px)",
              sm: "calc(var(--aa-radius) - 4px)",
            },
            keyframes: {
              "accordion-down": {
                from: { height: 0 },
                to: { height: "var(--radix-accordion-content-height)" },
              },
              "accordion-up": {
                from: { height: "color: hsl(var(--aa-primary));" },
                to: { height: 0 },
              },
              loader: {
                to: {
                  opacity: 0.1,
                  transform: "translate3d(0, -1rem, 0)",
                },
              },
            },
            animation: {
              "accordion-down": "accordion-down 0.2s ease-out",
              "accordion-up": "accordion-up 0.2s ease-out",
              "bounce-short": "bounce 1s 3",
              "pulse-2": "pulse 1s 2",
              loader: "loader 0.6s infinite alternate",
            },
          },
        },
        preflight: {
          "@layer base": getCssVariables(appearance),
          // body: `@apply bg-background text-foreground`,
          // "*": apply`border-border`,
          ".no-scrollbar::-webkit-scrollbar": {
            display: "none",
          },
          "no-scrollbar": {
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          },
          ".btn-parent:hover .btn-child": {
            color: "hsl(var(--aa-accent-foreground)) !important",
          },
          ".prose :where(strong):not(:where(.not-prose *))": {
            color: "unset !important",
          },
        },
      }),
      sheet,
    );
    root.adoptedStyleSheets = [sheet.target];
    observe(tw, root);
    return tw;
  }, [sheet]);

  const value = useMemo(() => ({ tw }), [tw]);

  const [observer, setObserver] = useState<MutationObserver | null>(null);
  useEffect(() => {
    if (!root) return;
    const htmlElement = document.getElementsByTagName("html")[0];
    if (htmlElement && !observer) {
      const checkDarkMode = () => {
        if (htmlElement.classList.contains("dark")) {
          root.host.classList.add("dark");
          setDarkMode(true);
        } else {
          root.host.classList.remove("dark");
          setDarkMode(false);
        }
      };
      const createObserver = () => {
        const observer = new MutationObserver((mutationsList, _) => {
          mutationsList.forEach(function (mutation) {
            if (
              mutation.type === "attributes" &&
              mutation.attributeName === "class"
            ) {
              checkDarkMode();
            }
          });
        });
        observer.observe(htmlElement, {
          attributes: true,
        });
        return observer;
      };

      setObserver(createObserver());
      checkDarkMode();
    }
  }, [root]);

  return (
    <TwindContext.Provider value={value}>
      {config.mode == "preview" && <DynamicStyle />}
      <DarkModeContext.Provider value={darkMode}>
        {children}
      </DarkModeContext.Provider>
    </TwindContext.Provider>
  );
}

export function TwindProvider({ children }: TwindRootProps) {
  const appearance = useAppearance();
  return (
    <ShadowDomRootDiv>
      <TwindProviderInner>
        <div
          className="font-sans antialiased"
          style={{
            fontSize: `${appearance.fontSize}px`,
          }}
        >
          {children}
        </div>
      </TwindProviderInner>
    </ShadowDomRootDiv>
  );
}
export function useTwind() {
  const context = useContext(TwindContext);
  if (!context) {
    throw new Error("useTwind must be used within TwindProvider");
  }
  return context;
}
