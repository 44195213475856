/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from "react";
import type { Message } from "ai";
import type { UseChatHelpers } from "ai/react/dist";
import { CheckIcon, StopCircleIcon, ThumbsDown, ThumbsUp } from "lucide-react";
import { usePostHog } from "posthog-js/react";

import { useAppearance, useBootstrapConfig } from "./chat-provider";
import { Markdown } from "./markdown";
import { Button } from "./ui/button";
import { IconRefresh } from "./ui/icons";

export interface ChatPanelProps
  extends Pick<UseChatHelpers, "stop" | "isLoading" | "reload"> {
  isLastMessage?: boolean;
  isFirstMessage?: boolean;
  message: Message;
}

export function ChatMessageActions({
  isLastMessage,
  isFirstMessage,
  message,
  stop,
  isLoading,
  reload,
}: ChatPanelProps) {
  const posthog = usePostHog();
  const [isUpvoted, setIsUpvoted] = React.useState(false);
  const [isDownvoted, setIsDownvoted] = React.useState(false);
  const config = useBootstrapConfig();
  const appearance = useAppearance();

  function RethinkButton() {
    return (
      <Button
        variant="ghost"
        onClick={async () => {
          await reload();
          posthog?.capture("chat_rethink");
        }}
        className="bg-background btn-parent"
      >
        <IconRefresh className="btn-child text-primary mr-2 h-4 w-4" />
        <Markdown maxWidth={120} className="btn-child text-primary text-xs">
          {appearance.rethink}
        </Markdown>
      </Button>
    );
  }

  return (
    <>
      {!isLoading &&
        isLastMessage &&
        !isFirstMessage &&
        message.role == "assistant" &&
        (!isUpvoted && !isDownvoted ? (
          <>
            <Button
              size="icon"
              className="text-primary"
              variant="ghost"
              onClick={() => {
                posthog?.capture("chat_like", {
                  message,
                });
                setIsUpvoted(true);
              }}
            >
              <ThumbsUp className="h-4 w-4" />
            </Button>
            <Button
              size="icon"
              className="text-primary"
              variant="ghost"
              onClick={() => {
                posthog?.capture("chat_dislike", {
                  message,
                });
                setIsDownvoted(true);
              }}
            >
              <ThumbsDown className="h-4 w-4" />
            </Button>
          </>
        ) : isUpvoted ? (
          <>
            <Button
              size="icon"
              variant="ghost"
              onClick={() => {
                setIsUpvoted(true);
              }}
            >
              <CheckIcon className="h-4 w-4" />
            </Button>
          </>
        ) : (
          <>
            <RethinkButton />
          </>
        ))}

      {config.mode == "preview" &&
        config.showChatMessageActions &&
        isFirstMessage && <RethinkButton />}

      {((config.mode == "preview" &&
        config.showChatMessageActions &&
        message.role == "user") ||
        (config.mode != "preview" &&
          isLoading &&
          isLastMessage &&
          message.role == "assistant")) && (
        <Button
          variant="ghost"
          onClick={() => {
            stop();
            posthog?.capture("chat_stop_writing");
          }}
          className="bg-background btn-parent"
        >
          <StopCircleIcon className="btn-child text-primary mr-2 h-4 w-4" />
          <Markdown maxWidth={120} className="btn-child text-primary text-xs">
            {appearance.stopWriting}
          </Markdown>
        </Button>
      )}
    </>
  );
}
