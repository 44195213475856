import * as React from "react";
import Autoplay from "embla-carousel-autoplay";

import { cn } from "../lib/utils";
import type { ChatProps } from "./chat";
import { useAppearance, useBootstrapConfig } from "./chat-provider";
import { Carousel, CarouselContent, CarouselItem } from "./ui/carousel";

export function FrequentlyAskedQuestions({
  append,
}: {
  append: ChatProps["append"];
}) {
  const appearance = useAppearance();
  const bootstrapConfig = useBootstrapConfig();

  if (appearance.faqs.length == 0) {
    return null;
  }

  const plugin = React.useRef(
    Autoplay({ delay: 5000, stopOnInteraction: true }),
  );

  const faqs = appearance.faqs.map((faq) => {
    const [heading, subheading, question] = faq.split("|");
    return {
      heading: heading?.trim(),
      subheading: subheading?.trim(),
      question: question?.trim(),
    };
  });

  const faqGroups = faqs.reduce(
    (acc, faq, index) => {
      const groupIndex = Math.floor(index / appearance.faqGroupSize);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex]!.push(faq);
      return acc;
    },
    [] as (typeof faqs)[],
  );

  return (
    <div>
      <div className="m-1">
        <Carousel
          opts={{
            align: "start",
          }}
          className="w-full"
          plugins={[plugin.current]}
          onMouseEnter={plugin.current.stop}
          onMouseLeave={plugin.current.reset}
        >
          <CarouselContent>
            {faqGroups.map((faqGroup, i) => {
              return (
                <CarouselItem key={`fg${i}`}>
                  <div className="m-1 max-w-xs md:max-w-none">
                    {faqGroup.map((faq, j) => (
                      <button
                        key={`f${j}`}
                        className={cn(
                          faq.subheading && faq.subheading.length > 0
                            ? "w-full"
                            : "",
                          "btn hover:bg-muted btn-neutral border-border group relative m-1 whitespace-nowrap rounded-xl border px-4 py-3 text-left opacity-70 md:whitespace-normal",
                        )}
                        onClick={async (e) => {
                          e.preventDefault();
                          if (bootstrapConfig.mode == "preview") {
                            return;
                          }

                          const content =
                            faq.question ?? faq.subheading ?? faq.heading ?? "";

                          if (content.length == 0) {
                            return;
                          }

                          await append({
                            content,
                            role: "user",
                            createdAt: new Date(),
                          });
                        }}
                      >
                        <div className="flex w-full items-center justify-between">
                          <div
                            className="text-card-foreground animate-pulse-2 flex flex-col overflow-hidden"
                            style={{
                              animationDelay: `3000ms`,
                            }}
                          >
                            <div className="truncate">{faq.heading}</div>
                            <div className="truncate font-normal opacity-50">
                              {faq.subheading}
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          {/* {faqGroups.length > 1 && (
            <>
              <CarouselPrevious className="text-primary border-border" />
              <CarouselNext className="text-primary border-border" />
            </>
          )} */}
        </Carousel>
      </div>
    </div>
  );
}
