import React from "react";
import { Info } from "lucide-react";

import { cn } from "../lib/utils";
import { ExternalLink } from "./external-link";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export function FooterText({ className, ...props }: React.ComponentProps<"p">) {
  return (
    <div className={cn("flex justify-center px-2 py-1", className)}>
      <div className="flex flex-row items-center justify-center ">
        <div>
          <Tooltip>
            <TooltipTrigger>
              <Info
                className="text-muted-foreground h-3 w-3 opacity-50"
                style={{
                  strokeWidth: 3,
                }}
              />
            </TooltipTrigger>
            <TooltipContent>
              <p>
                Your data is protected with encryption. Read our{" "}
                <ExternalLink
                  className="underline"
                  href="https://apeelingai.com/privacy"
                >
                  privacy policy
                </ExternalLink>{" "}
                for more information. <br />
                Representative may make mistakes, consider checking important
                information.
              </p>
            </TooltipContent>
          </Tooltip>
        </div>
        <div>
          <p
            className={cn(
              "text-muted-foreground px-2 text-center text-xs leading-normal",
            )}
            {...props}
          >
            powered by{" "}
            <ExternalLink href="https://apeelingai.com/">
              <span className="font-bold tracking-tight hover:cursor-pointer">
                apeeling<span className="text-amber-400">ai</span>
              </span>
            </ExternalLink>
          </p>
        </div>
      </div>
    </div>
  );
}
