import type { UseChatHelpers } from "ai/react";
import { usePostHog } from "posthog-js/react";

import { FooterText } from "./footer";
import { PromptForm } from "./prompt-form";

export type ChatPanelProps = Pick<
  UseChatHelpers,
  "append" | "isLoading" | "input" | "setInput"
>;

export function ChatPanel({
  isLoading,
  append,
  input,
  setInput,
}: ChatPanelProps) {
  const posthog = usePostHog();
  return (
    <div>
      <div className="mx-auto">
        <PromptForm
          onSubmit={async (value) => {
            await append({
              content: value,
              role: "user",
              createdAt: new Date(),
            });

            posthog?.capture("chat_request", {
              message: value,
            });
          }}
          input={input}
          setInput={setInput}
          isLoading={isLoading}
        />
        <FooterText className="bg-muted" />
      </div>
    </div>
  );
}
