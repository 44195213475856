import { useEffect, useMemo, useState } from "react";
import { usePostHog } from "posthog-js/react";

import {
  cn,
  getFromLocalStorageWithExpiry,
  mapNullToUndefined,
  setInLocalStorageWithExpiry,
} from "../lib/utils";
import { Chat } from "./chat";
import { ChatActions } from "./chat-actions";
import { ChatBubble } from "./chat-bubble";
import {
  getRandomWelcomeMessageFromAppearance,
  useBootstrapConfig,
  useChatFromLocalStorage,
  useSession,
} from "./chat-provider";
import { ChatSkeleton } from "./chat-skeleton";

export const BubbleChat = () => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [appear, setAppear] = useState(false);
  const posthog = usePostHog();
  const bootstrapConfig = useBootstrapConfig();

  if (bootstrapConfig.mode == "preview") {
    console.error("Bubble chat is not supported in preview mode.");
    return null;
  }

  const {
    config: { appearance },
    sessionId,
  } = useSession();

  const [open, setOpen] = useState<boolean>(
    getFromLocalStorageWithExpiry<boolean>(
      `apeeling-ai-chat-opened-${bootstrapConfig.representativeId}`,
    ) ?? (appearance.autoOpenChat ? true : false),
  );

  useEffect(() => {
    setInLocalStorageWithExpiry(
      `apeeling-ai-chat-opened-${bootstrapConfig.representativeId}`,
      open,
    );
    if (open) {
      posthog?.capture("chat_opened");
    } else {
      posthog?.capture("chat_closed");
    }
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  }, [open]);

  useEffect(() => {
    setTimeout(() => {
      setAppear(true);
    }, 10);
  }, []);

  const initialMessages = useMemo(
    () => getRandomWelcomeMessageFromAppearance(appearance),
    [appearance],
  );
  const chat = useChatFromLocalStorage(
    {
      initialMessages,
    },
    sessionId,
  );

  return (
    <div
      className={"fixed"}
      style={{
        zIndex: 2147483647,
        top: mapNullToUndefined(appearance.layout.bubble.top),
        bottom: mapNullToUndefined(appearance.layout.bubble.bottom),
        left: mapNullToUndefined(appearance.layout.bubble.left),
        right: mapNullToUndefined(appearance.layout.bubble.right),
      }}
    >
      <div
        className={cn(
          "flex gap-2 transition-all duration-1000",
          appear ? "opacity-100" : "opacity-0",
          appearance.layout.bubble.bottom != null
            ? "flex-col"
            : "flex-col-reverse",
        )}
      >
        <div
          className={cn(open ? "md:max-w-lg" : "h-0")}
          style={{
            width: open
              ? `calc(100dvw - ${
                  (appearance.layout.bubble.left ??
                    appearance.layout.bubble.right ??
                    0) * 2
                }px)`
              : "80px",
          }}
        >
          {!isTransitioning && open && <Chat {...chat} />}

          {isTransitioning && (
            <div
              className={cn(
                "bg-background flex max-h-[80dvh] flex-col overflow-hidden rounded-2xl shadow-lg transition-all duration-100",
                open ? "" : "h-0 w-20",
              )}
            >
              <ChatSkeleton />
            </div>
          )}
        </div>

        <div
          className={cn(
            "flex items-center gap-2",
            appearance.layout.bubble.right != null
              ? "justify-end"
              : "justify-start",
          )}
        >
          <ChatBubble
            isTransitioning={isTransitioning}
            open={open}
            onClick={() => {
              if (isTransitioning) return;
              setIsTransitioning(true);
              setOpen((prev) => !prev);
            }}
          />

          {open && !isTransitioning && chat.messages.length > 1 && (
            <ChatActions setMessages={chat.setMessages} />
          )}
        </div>
      </div>
    </div>
  );
};
