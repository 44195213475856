import Ajv from "ajv";
import { z } from "zod";

import { sumBoolArray } from "../utils";

export const MessageAnnotationItemToolCallsModel = z.object({
  type: z.literal("tool_calls"),
  calls: z.array(
    z.object({
      name: z.string(),
      description: z.string(),
      id: z.string(),
      arguments: z.string(),
      type: z.string(),
    }),
  ),
});
export type MessageAnnotationItemToolCallsModelType = z.infer<
  typeof MessageAnnotationItemToolCallsModel
>;

export const MessageAnnotationItemToolCallResultRetrieval = z.object({
  type: z.literal("retrieval"),
  overview: z.boolean(),
  results: z
    .object({
      url: z.string(),
      title: z.string(),
      imageUrl: z.string().optional().nullable(),
    })
    .array(),
});
export type MessageAnnotationItemToolCallResultRetrievalType = z.infer<
  typeof MessageAnnotationItemToolCallResultRetrieval
>;
export const MessageAnnotationItemToolCallResult = z.object({
  type: z.literal("tool_call_result"),
  id: z.string(),
  tool: z
    .discriminatedUnion("type", [MessageAnnotationItemToolCallResultRetrieval])
    .optional()
    .nullable(),
});
export type MessageAnnotationItemToolCallResultType = z.infer<
  typeof MessageAnnotationItemToolCallResult
>;
export const MessageAnnotationItemModel = z.discriminatedUnion("type", [
  MessageAnnotationItemToolCallResult,
  MessageAnnotationItemToolCallsModel,
  z.object({
    type: z.literal("success"),
  }),
  z.object({
    type: z.literal("error"),
  }),
]);
export type MessageAnnotationItemModelType = z.infer<
  typeof MessageAnnotationItemModel
>;

export const FunctionParametersModel = z
  .string()
  .min(1)
  .max(4096)
  .superRefine((str, ctx) => {
    const ajv = new Ajv();
    try {
      const schema = JSON.parse(str) as {
        type?: string;
        properties?: Record<string, { type?: string }>;
      };

      // assert schema is object
      if (schema?.type !== "object") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Schema must be an object",
        });
        return false;
      }

      // assert that all property keys are strings of length > 0
      if (
        !Object.keys(schema.properties ?? {}).every(
          (v) => typeof v === "string" && v.length > 0,
        )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "All properties must have a non-empty string key",
        });
        return false;
      }

      // assert that all properties have a type
      if (
        !Object.values(schema.properties ?? {}).every(
          (v) => v.type && v.type.length > 0,
        )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "All properties must have a type",
        });
        return false;
      }

      return ajv.compile(schema);
    } catch (e) {
      if (e instanceof Error) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: e.message,
        });
      } else {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Unknown error",
        });
      }
      return false;
    }
  });

export type FunctionParametersModelType = z.infer<
  typeof FunctionParametersModel
>;

const ColorsModel = z.object({
  background: z.string().max(128),
  foreground: z.string().max(128),
  card: z.string().max(128),
  cardForeground: z.string().max(128),
  popover: z.string().max(128),
  popoverForeground: z.string().max(128),
  primary: z.string().max(128),
  primaryForeground: z.string().max(128),
  secondary: z.string().max(128),
  secondaryForeground: z.string().max(128),
  muted: z.string().max(128),
  mutedForeground: z.string().max(128),
  accent: z.string().max(128),
  accentForeground: z.string().max(128),
  destructive: z.string().max(128),
  destructiveForeground: z.string().max(128),
  border: z.string().max(128),
  input: z.string().max(128),
  ring: z.string().max(128),
});
export const ImageModel = z.object({
  dark: z.string().max(32768).nullable(),
  light: z.string().max(32768).nullable(),
});
export const AppearanceModel = z.object({
  displayName: z.string().min(1).max(64),
  description: z.string().nullable(),
  fontSize: z.number().min(1).max(64),
  faqs: z.array(z.string().min(1).max(512)),
  faqGroupSize: z.number().min(1).max(10),
  autoOpenChat: z.boolean(),
  showUnreadMessages: z.boolean(),
  rethink: z.string().min(1).max(64),
  stopWriting: z.string().min(1).max(64),
  errorOccured: z.string().min(1).max(128),
  restartChat: z.string().min(1).max(128),
  typeMessageHere: z.string().min(1).max(128),
  serverError: z.string().min(1).max(128),
  bubbleText: z.string().max(128).nullable(),
  bubbleIconSize: z.number().min(1).max(20),
  messages: z.object({
    welcome: z.array(z.string().min(1).max(512)),
  }),
  layout: z.object({
    bubble: z
      .object({
        top: z.number().nullable(),
        left: z.number().nullable(),
        right: z.number().nullable(),
        bottom: z.number().nullable(),
      })
      .superRefine((obj, ctx) => {
        if (obj.top != null && obj.bottom != null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Cannot set both top and bottom",
          });
          return false;
        }
        if (obj.left != null && obj.right != null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Cannot set both left and right",
          });
          return false;
        }
        if (
          sumBoolArray([
            obj.top != null,
            obj.left != null,
            obj.right != null,
            obj.bottom != null,
          ]) != 2
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              "Exactly two of the following must be set: top, left, right, bottom",
          });
          return false;
        }
        return true;
      }),
  }),
  images: z.object({
    bubble: ImageModel,
    header: ImageModel,
    user: ImageModel,
    assistant: ImageModel,
  }),
  variables: ColorsModel.extend({
    dark: ColorsModel,
    radius: z.string().max(128),
  }),
});
export type AppearanceModelType = z.infer<typeof AppearanceModel>;
export const AppearanceDefaultInstance: AppearanceModelType = {
  displayName: "Representative",
  description: null,
  fontSize: 16,
  faqs: [],
  faqGroupSize: 1,
  autoOpenChat: false,
  showUnreadMessages: true,
  bubbleIconSize: 10,
  rethink: "Rethink",
  stopWriting: "Stop writing",
  bubbleText: "**We are online**",
  errorOccured: "An error occurred",
  restartChat: "Restart chat",
  typeMessageHere: "Type a message here",
  serverError: "Oops, something went wrong!",
  messages: {
    welcome: ["Hello! How can I help you?"],
  },
  layout: {
    bubble: {
      top: null,
      left: null,
      right: 8,
      bottom: 8,
    },
  },
  images: {
    bubble: { dark: null, light: null },
    user: { dark: null, light: null },
    assistant: { dark: null, light: null },
    header: { dark: null, light: null },
  },
  variables: {
    background: "0 0% 100%",
    foreground: "222.2 84% 4.9%",
    card: "0 0% 100%",
    cardForeground: "222.2 84% 4.9%",
    popover: "0 0% 100%",
    popoverForeground: "222.2 84% 4.9%",
    primary: "222.2 47.4% 11.2%",
    primaryForeground: "210 40% 98%",
    secondary: "210 40% 96.1%",
    secondaryForeground: "222.2 47.4% 11.2%",
    muted: "210 40% 96.1%",
    mutedForeground: "215.4 16.3% 46.9%",
    accent: "210 40% 96.1%",
    accentForeground: "222.2 47.4% 11.2%",
    destructive: "0 84.2% 60.2%",
    destructiveForeground: "210 40% 98%",
    border: "214.3 31.8% 91.4%",
    input: "214.3 31.8% 91.4%",
    ring: "222.2 84% 4.9%",
    dark: {
      background: "222.2 84% 4.9%",
      foreground: "210 40% 98%",
      card: "222.2 84% 4.9%",
      cardForeground: "210 40% 98%",
      popover: "222.2 84% 4.9%",
      popoverForeground: "210 40% 98%",
      primary: "210 40% 98%",
      primaryForeground: "222.2 47.4% 11.2%",
      secondary: "217.2 32.6% 17.5%",
      secondaryForeground: "210 40% 98%",
      muted: "217.2 32.6% 17.5%",
      mutedForeground: "215 20.2% 65.1%",
      accent: "217.2 32.6% 17.5%",
      accentForeground: "210 40% 98%",
      destructive: "0 62.8% 30.6%",
      destructiveForeground: "220 37% 8%",
      border: "217.2 32.6% 17.5%",
      input: "217.2 32.6% 17.5%",
      ring: "212.7 26.8% 83.9%",
    },
    radius: "0.5rem",
  },
};

const BootstrapModelBase = z.object({
  cacheDisabled: z.boolean().default(false).optional(),
});
const BootstrapModelOutside = BootstrapModelBase.extend({
  rootElementId: z.string(),
});
const BootstrapModelInside = BootstrapModelBase.extend({});
export const BootstrapModel = z.discriminatedUnion("mode", [
  BootstrapModelOutside.extend({
    baseUri: z.string().url(),
    representativeId: z.string().min(1),
    mode: z.literal("bubble"),
  }),
  BootstrapModelInside.extend({
    baseUri: z.string().url(),
    representativeId: z.string().min(1),
    mode: z.literal("chat-mounted"),
  }),
  BootstrapModelInside.extend({
    baseUri: z.string().url(),
    representativeId: z.string().min(1),
    mode: z.literal("bubble-mounted"),
  }),
  BootstrapModelInside.extend({
    mode: z.literal("preview"),
    appearance: AppearanceModel,
    showError: z.boolean().default(true).optional(),
    showChatMessageActions: z.boolean().default(true).optional(),
  }),
]);
export type BootstrapModelType = z.infer<typeof BootstrapModel>;
