import { useEffect, useState } from "react";

import { cn, getIconComponentFromAppearance } from "../lib/utils";
import { useAppearance } from "./chat-provider";
import { Markdown } from "./markdown";
import { useDarkMode } from "./tailwind-provider";
import { Icons } from "./ui/icons.shared";

export function ChatBubble({
  open,
  isTransitioning,
  onClick,
}: {
  open: boolean;
  isTransitioning: boolean;
  onClick?: () => void;
}) {
  const appearance = useAppearance();
  const darkMode = useDarkMode();
  const [showBadge, setShowBadge] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowBadge(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (open) setOpened(true);
  }, [open]);

  return (
    <div className="relative flex flex-row-reverse items-center justify-center">
      {!isTransitioning && !open && appearance.bubbleText && (
        <div
          className={cn(
            "bg-accent -ml-4 cursor-pointer rounded-r-full p-2 duration-300 ease-in-out",
          )}
          onClick={onClick}
        >
          {!isTransitioning && (
            <Markdown
              className="text-accent-foreground ml-3 mr-2 text-xl"
              maxWidth={150}
            >
              {appearance.bubbleText}
            </Markdown>
          )}
        </div>
      )}
      <div
        className={cn(
          "bg-accent transition-all duration-300 ease-in-out",
          "flex cursor-pointer items-center justify-center rounded-full",
          open ? "h-10 w-10" : "h-20 w-20",
        )}
        onClick={onClick}
      >
        <div
          className={cn(
            "flex items-center justify-center",
            open ? "h-5 w-5" : "h-20 w-20 overflow-hidden rounded-full",
          )}
        >
          {!isTransitioning && open && (
            <Icons.close
              className={cn(
                "stroke-accent-foreground",
                open ? "h-5 w-5" : "h-10 w-10",
              )}
            />
          )}
          {!isTransitioning &&
            !open &&
            getIconComponentFromAppearance({
              appearance,
              iconName: "bubble",
              size: appearance.bubbleIconSize,
              darkMode,
            })}
        </div>

        {appearance.showUnreadMessages &&
          !opened &&
          showBadge &&
          !open &&
          !isTransitioning && (
            <div className="bg-secondary absolute left-0 top-0 flex h-6 w-6 animate-bounce items-center justify-center rounded-full">
              <Markdown className="text-secondary-foreground">**1**</Markdown>
            </div>
          )}
      </div>
    </div>
  );
}
