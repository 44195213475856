import { clsx } from "clsx";
import type { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

import type { AppearanceModelType } from "@apeeling/shared/models/client";

import { Icons } from "../components/ui/icons.shared";

export interface Logger {
  info: (message: string, args: Record<string, unknown> | Error) => void;
  error: (message: string, args: Record<string, unknown> | Error) => void;
  warn: (message: string, args: Record<string, unknown> | Error) => void;
}

// chunk array into smaller arrays of max size n
export function chunkArray<T>(array: T[], n: number): T[][] {
  return Array.from({ length: Math.ceil(array.length / n) }, (_, i) =>
    array.slice(i * n, i * n + n),
  );
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function pickRandom<T>(array: T[]): T | undefined {
  return array[Math.floor(Math.random() * array.length)];
}

export function getFromLocalStorageWithExpiry<T>(key: string): T | undefined {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return undefined;

  const item = JSON.parse(itemStr) as {
    value: T;
    expiry: number;
  };

  if (Date.now() > item.expiry) {
    localStorage.removeItem(key);
    return undefined;
  }
  return item.value;
}
export function setInLocalStorageWithExpiry<T>(
  key: string,
  value: T,
  ttlMs: number = 1000 * 60 * 5,
): void {
  const item = {
    value,
    expiry: Date.now() + ttlMs,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getIconComponentFromAppearance({
  appearance,
  size,
  iconName,
  className,
  darkMode,
}: {
  appearance?: AppearanceModelType;
  iconName: keyof AppearanceModelType["images"];
  size?: number;
  className?: string;
  darkMode?: boolean;
}) {
  const image = appearance?.images[iconName];
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if ((darkMode && image?.dark) || (!darkMode && image?.light)) {
    return (
      <img
        src={darkMode ? image.dark! : image.light!}
        width={(size ?? 8) * 4}
        height={(size ?? 8) * 4}
        alt={`${iconName} icon`}
      />
    );
  }

  switch (iconName) {
    case "user":
      return (
        <Icons.user
          className={`w-[${(size ?? 8) * 4}px] h-[${(size ?? 8) * 4}px] ${className ?? ""}`}
        />
      );
    case "assistant":
      return (
        <Icons.robot
          className={`w-[${(size ?? 8) * 4}px] h-[${(size ?? 8) * 4}px] ${className ?? ""}`}
        />
      );
    case "header":
      return (
        <Icons.logo
          className={`w-[${(size ?? 8) * 4}px] h-[${(size ?? 8) * 4}px] ${className ?? ""}`}
        />
      );
    case "bubble":
      return (
        <Icons.logo
          className={`w-[${(size ?? 8) * 4}px] h-[${(size ?? 8) * 4}px] ${className ?? ""}`}
        />
      );
  }
}

export function mapNullToUndefined<T>(val: T | null): T | undefined {
  return val ?? undefined;
}
