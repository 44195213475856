import { z } from "zod";

import { AppearanceModel } from "./client";

export interface ApiFunction<In extends z.ZodType, Out extends z.ZodType> {
  url: string;
  contract: ApiContract<In, Out>;
  method: string;
  body: z.infer<In>;
}
export interface ApiContract<In extends z.ZodType, Out extends z.ZodType> {
  in: In;
  out: Out;
}

export const StreamingTextReponseLiteral = z.literal("StreamingResponse");

export const configEndpointUri = (baseUrl: string, representativeId: string) =>
  `${baseUrl}/api/config/${representativeId}`;
export const ConfigEndpointContract = {
  in: z.undefined(),
  out: z.object({
    appearance: AppearanceModel,
    enabled: z.boolean(),
  }),
};
export type ConfigEndpointInType = z.infer<
  (typeof ConfigEndpointContract)["in"]
>;
export type ConfigEndpointOutType = z.infer<
  (typeof ConfigEndpointContract)["out"]
>;
export function configEndpoint(): (
  baseUrl: string,
  representativeId: string,
) => ApiFunction<
  (typeof ConfigEndpointContract)["in"],
  (typeof ConfigEndpointContract)["out"]
> {
  return (baseUrl, representativeId) => ({
    url: configEndpointUri(baseUrl, representativeId),
    contract: ConfigEndpointContract,
    method: "GET",
    body: undefined,
  });
}

export const MessageModel = z.discriminatedUnion("role", [
  z.object({
    role: z.literal("user"),
    content: z.string().max(4096),
  }),
  z.object({
    role: z.literal("assistant"),
    content: z.string().max(4096).nullable(),
    tool_calls: z
      .object({
        id: z.string().max(16),
        type: z.literal("function"),
        function: z.object({
          name: z.string().max(128),
          arguments: z.string().max(4096),
        }),
      })
      .array()
      .optional(),
  }),
  z.object({
    role: z.literal("tool"),
    content: z.string().max(2048),
    tool_call_id: z.string().max(16),
  }),
]);
export const MessageArrayModel = z.array(MessageModel).max(100);
export type MessageModelType = z.infer<typeof MessageModel>;

export const SessionIdModel = z.string().uuid();
export const chatEndpointUri = (
  baseUrl: string,
  representativeId: string,
  sessionId: string,
) => `${baseUrl}/api/chat/${representativeId}/${sessionId}`;
export const ChatEndpointContract = {
  in: z.object({
    messages: MessageArrayModel.min(2).refine(
      (msgs) => {
        const lastMessage = msgs[msgs.length - 1];

        return lastMessage?.role == "user";
      },
      { message: "Last message must be from user" },
    ),
  }),
  out: StreamingTextReponseLiteral,
};
export type ChatEndpointInType = z.infer<(typeof ChatEndpointContract)["in"]>;
export type ChatEndpointOutType = z.infer<(typeof ChatEndpointContract)["out"]>;

export const FunctionReturnModel = z.object({
  error: z.boolean().optional().nullable(),
  skip: z.boolean().optional().nullable(),
  result: z
    .union([z.string(), z.record(z.string(), z.any())])
    .optional()
    .nullable(),
});
export type FunctionReturnModelType = z.infer<typeof FunctionReturnModel>;
