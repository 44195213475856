import { createContext, useContext, useEffect, useState } from "react";
import { nanoid } from "ai";
import type { Message, UseChatOptions } from "ai/react";
import { useChat as ai_useChat } from "ai/react";
import { posthog } from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { v4 as uuidv4 } from "uuid";

import { chatEndpointUri, configEndpoint } from "@apeeling/shared/models/api";
import type { ConfigEndpointOutType } from "@apeeling/shared/models/api";
import { BootstrapModel } from "@apeeling/shared/models/client";
import type {
  AppearanceModelType,
  BootstrapModelType,
} from "@apeeling/shared/models/client";

import { useApi } from "../lib/api";
import {
  getFromLocalStorageWithExpiry,
  pickRandom,
  setInLocalStorageWithExpiry,
} from "../lib/utils";
import { TwindProvider } from "./tailwind-provider";

const SessionContext = createContext<
  | {
      sessionId?: string;
      resetSession: () => void;
      config: ConfigEndpointOutType;
    }
  | undefined
>(undefined);

export function useSession() {
  const context = useContext(SessionContext);
  if (!context)
    throw new Error("useSession must be used within a SessionProvider");
  return context;
}
export function useConfig() {
  const { config } = useSession();
  return config;
}
export function useAppearance() {
  const { config } = useSession();
  return config.appearance;
}

export function useChatFromLocalStorage(
  { initialMessages, ...options }: Omit<UseChatOptions, "api"> = {},
  sessionId: string | undefined,
) {
  const storedMessages = sessionId
    ? (JSON.parse(
        localStorage.getItem(`apeeling-ai-chat-messages-${sessionId}`) ?? "[]",
      ) as Message[])
    : [];

  const { messages, ...rest } = useChat({
    initialMessages:
      storedMessages.length > (initialMessages?.length ?? 0)
        ? storedMessages
        : initialMessages,
    ...options,
  });

  useEffect(() => {
    localStorage.setItem(
      `apeeling-ai-chat-messages-${sessionId}`,
      JSON.stringify(messages),
    );
  }, [messages]);

  return { messages, ...rest };
}

export function createChatMessage(role: "user" | "assistant", content: string) {
  return {
    id: nanoid(),
    role,
    content,
    createdAt: new Date(),
  } as Message;
}

export function getRandomWelcomeMessageFromAppearance(
  appearance: AppearanceModelType,
) {
  return [
    createChatMessage(
      "assistant",
      pickRandom(
        appearance.messages.welcome ?? ["Hallo, hoe kan ik u helpen?"],
      )!,
    ),
  ];
}

export function useChat({
  initialMessages,
  ...options
}: Omit<UseChatOptions, "api" | "sendExtraMessageFields"> = {}) {
  const config = useBootstrapConfig();
  const posthog = usePostHog();
  if (config.mode == "preview") {
    return ai_useChat({
      api: "",
      initialMessages,
      ...options,
    });
  }

  const baseUri = config.baseUri;
  const { sessionId } = useSession();
  return ai_useChat({
    api: chatEndpointUri(baseUri, config.representativeId, sessionId!),
    onError: (error) => {
      posthog?.capture("chat_error", {
        error,
      });
    },
    onFinish: (message) => {
      posthog?.capture("chat_reply", {
        message,
      });
    },
    onResponse: (response) => {
      posthog?.capture("chat_response", {
        response,
      });
    },
    initialMessages,
    ...options,
  });
}

const generateSessionId = () => {
  return uuidv4();
};

function InnerLoader({ children }: { children: React.ReactNode }) {
  const { fetch } = useApi();

  const [initialized, setInitialized] = useState(false);
  const bootstrapConfig = useBootstrapConfig();
  const [config, setConfig] = useState<ConfigEndpointOutType | undefined>(
    undefined,
  );
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);

  const createSession = (representativeId: string) => {
    const sessionId = generateSessionId();
    setInLocalStorageWithExpiry(
      `apeeling-ai-chat-session-id-${representativeId}`,
      sessionId,
      1000 * 60 * 60 * 24,
    );
    return sessionId;
  };

  useEffect(() => {
    if (initialized) return;
    setInitialized(true);

    async function init() {
      const createOrGetSession = (representativeId: string) => {
        return (
          getFromLocalStorageWithExpiry<string>(
            `apeeling-ai-chat-session-id-${representativeId}`,
          ) ?? createSession(representativeId)
        );
      };

      const fetchOrGetConfig = async (representativeId: string) => {
        const savedConfig =
          getFromLocalStorageWithExpiry<ConfigEndpointOutType>(
            `apeeling-ai-chat-config-${representativeId}`,
          );
        if (savedConfig === undefined || bootstrapConfig.cacheDisabled) {
          const config = await fetch(configEndpoint());
          setInLocalStorageWithExpiry(
            `apeeling-ai-chat-config-${representativeId}`,
            config,
            1000 * 60 * 5,
          );
          return config;
        } else {
          return savedConfig;
        }
      };

      if (bootstrapConfig.mode == "preview") {
        setSessionId(undefined);
        setConfig({
          enabled: true,
          appearance: bootstrapConfig.appearance,
        });
      } else {
        setSessionId(createOrGetSession(bootstrapConfig.representativeId));
        const config = await fetchOrGetConfig(bootstrapConfig.representativeId);

        setConfig(config);
      }
    }

    init().catch((x: Error) => {
      console.error("Failed to initialize chat", x);
    });
  }, []);

  useEffect(() => {
    if (
      initialized &&
      config &&
      bootstrapConfig.mode == "preview" &&
      bootstrapConfig.appearance
    ) {
      setConfig({
        ...config,
        appearance: bootstrapConfig.appearance,
      });
    }
  }, [initialized, bootstrapConfig]);

  useEffect(() => {
    if (bootstrapConfig.mode == "preview") return;
    if (!sessionId) return;
    if (!config?.appearance) return;

    posthog?.identify(`${bootstrapConfig.representativeId}-${sessionId}`, {
      representativeId: bootstrapConfig.representativeId,
      representativeDisplayName: config.appearance.displayName,
      sessionId,
    });
    posthog?.capture("chat_loaded");
  }, [sessionId, config]);

  return (sessionId ?? bootstrapConfig.mode == "preview") &&
    config &&
    config.enabled ? (
    <SessionContext.Provider
      value={{
        resetSession: () =>
          setSessionId(
            bootstrapConfig.mode == "preview"
              ? undefined
              : createSession(bootstrapConfig.representativeId),
          ),
        sessionId,
        config,
      }}
    >
      <TwindProvider>{children}</TwindProvider>
    </SessionContext.Provider>
  ) : null;
}
const RootContext = createContext<BootstrapModelType | undefined>(undefined);

export const useBootstrapConfig = () => {
  const config = useContext(RootContext);

  if (!config) {
    throw new Error("Bootstrap config not found");
  }

  return config;
};

export const ChatProvider = ({
  children,
  bootstrap,
}: {
  children: React.ReactNode;
  bootstrap: BootstrapModelType;
}) => {
  return bootstrap.mode == "preview" ? (
    <RootContext.Provider value={BootstrapModel.parse(bootstrap)}>
      <InnerLoader>{children}</InnerLoader>
    </RootContext.Provider>
  ) : (
    <PostHogProvider
      apiKey={process.env.NEXT_PUBLIC_POSTHOG_KEY}
      options={{ api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST }}
    >
      <RootContext.Provider value={BootstrapModel.parse(bootstrap)}>
        <InnerLoader>{children}</InnerLoader>
      </RootContext.Provider>
    </PostHogProvider>
  );
};
