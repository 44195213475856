import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import { BootstrapModel } from "@apeeling/shared/models/client";

import { BubbleChat } from "./components/bubble-chat";
import { ChatProvider } from "./components/chat-provider";

function init(reactRootElement: React.ReactNode, rootElement: HTMLElement) {
  const root = createRoot(rootElement);
  root.render(reactRootElement);
}

export function mountFromWindow() {
  if (!window.apeeling) {
    throw new Error("No configuration found in window!");
  }

  Sentry.init({
    dsn: "https://7fc5f577a81fe79053805e24c5d8240f@o4507086109736960.ingest.de.sentry.io/4507086122319952",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/universe\.apeelingai\.com\/api/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  const model = BootstrapModel.parse(window.apeeling);

  if (document.adoptedStyleSheets === undefined) {
    throw new Error("No adoptedStyleSheets support");
  } else {
    if (model.mode == "bubble") {
      const rootElement = document.createElement("div");
      rootElement.id = model.rootElementId;

      document.body.appendChild(rootElement);

      init(
        <ChatProvider bootstrap={model}>
          <BubbleChat />
        </ChatProvider>,
        rootElement,
      );
    } else {
      throw new Error(`Unsupported mode ${model.mode}`);
    }
  }
}

try {
  mountFromWindow();
} catch (e) {
  if (e instanceof Error) {
    console.error(`Bootstrap failed: ${e.message}`);
  }
}
