import type { FC } from "react";
import React, { memo } from "react";
import type { Options } from "react-markdown";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { cn } from "../lib/utils";
import { useDarkMode } from "./tailwind-provider";

export const MemoizedReactMarkdown: FC<Options> = memo(
  ReactMarkdown,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className,
);

class ErrorBoundary extends React.Component<
  { children: JSX.Element },
  { hasError: boolean }
> {
  constructor(props: { children: JSX.Element }) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error rendering markdown!</h2>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export const Markdown = ({
  children,
  className,
  maxWidth,
  minWidth,
}: {
  children: string;
  className?: string;
  maxWidth?: number;
  minWidth?: number;
}) => {
  const darkMode = useDarkMode();
  return (
    <ErrorBoundary>
      <MemoizedReactMarkdown
        className={cn(
          "prose prose-p:leading-relaxed prose-pre:p-0 break-words",
          darkMode && "!prose-invert",
          maxWidth && "whitespace-nowrap",
          className,
        )}
        remarkPlugins={[remarkGfm]}
        components={{
          a({ children, ...props }) {
            return (
              <a target="_blank" {...props}>
                {children}
              </a>
            );
          },
          p({ children }) {
            return (
              <p
                style={{
                  ...(maxWidth
                    ? {
                        maxWidth: maxWidth,
                      }
                    : {}),
                  ...(minWidth
                    ? {
                        minWidth: minWidth,
                      }
                    : {}),
                }}
                className="mb-2 overflow-hidden text-ellipsis last:mb-0"
              >
                {children}
              </p>
            );
          },
        }}
      >
        {children}
      </MemoizedReactMarkdown>
    </ErrorBoundary>
  );
};
