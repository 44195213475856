import * as React from "react";
import type { UseChatHelpers } from "ai/react";
import Textarea from "react-textarea-autosize";

import { useEnterSubmit } from "../lib/hooks/use-enter-submit";
import { useAppearance, useBootstrapConfig } from "./chat-provider";
import { Button } from "./ui/button";
import { IconArrowElbow } from "./ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export interface PromptProps
  extends Pick<UseChatHelpers, "input" | "setInput"> {
  onSubmit: (value: string) => Promise<void>;
  isLoading: boolean;
}

export function PromptForm({
  onSubmit,
  input,
  setInput,
  isLoading,
}: PromptProps) {
  const { formRef, onKeyDown } = useEnterSubmit();
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const config = useBootstrapConfig();
  const appearance = useAppearance();

  // React.useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, []);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        if (config.mode === "preview") {
          return;
        }
        if (!input?.trim()) {
          return;
        }
        setInput("");
        await onSubmit(input);
        inputRef.current?.focus();
      }}
      className="m-0"
      ref={formRef}
    >
      <div className="bg-muted relative flex max-h-60 w-full grow flex-col overflow-hidden pl-2 pr-8">
        {isLoading && (
          <div className="flex justify-center gap-2 p-10">
            <span className="animate-loader bg-muted-foreground h-3 w-3 rounded-full"></span>
            <span
              className="animate-loader bg-muted-foreground h-3 w-3 rounded-full"
              style={{
                animationDelay: "0.2s",
              }}
            ></span>
            <span
              className="animate-loader bg-muted-foreground h-3 w-3 rounded-full"
              style={{
                animationDelay: "0.4s",
              }}
            ></span>
          </div>
        )}
        {!isLoading && (
          <>
            <Textarea
              ref={inputRef}
              tabIndex={0}
              onKeyDown={onKeyDown}
              rows={1}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={appearance.typeMessageHere}
              spellCheck={false}
              maxLength={1024}
              className="placeholder-muted-foreground text-foreground min-h-[4.5em] w-full resize-none bg-transparent py-[1.3em] pl-4 pr-12 focus-within:outline-none "
            />
            <div className="absolute right-5 top-4">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="submit"
                    size="icon"
                    disabled={isLoading || input === ""}
                  >
                    <IconArrowElbow />
                    <span className="sr-only">
                      {appearance.typeMessageHere}
                    </span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{appearance.typeMessageHere}</TooltipContent>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </form>
  );
}
