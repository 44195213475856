export const Icons = {
  logo: (props: { className?: string }) => (
    <svg {...props} viewBox="0 0 111.81 122.88">
      <g id="g284">
        <path
          style={{ fill: "#000" }}
          d="m 100.59121,66.83 c 1.71,-4.59 3.11,-9.33 4.23,-14.18 1.29,-5.54 2.21,-11.24 2.8,-17.03 0.43,-4.27 1.14,-12.22 0.29,-19.42 -0.93,-7.78 -3.67,-14.72 -10.420001,-16.01 -11.52,-2.2 -16.95,15.47 -20.2,26.01 -0.51,1.67 -0.97,3.15 -1.37,4.28 -1.09,3.1 -2.18,6.08 -3.31,8.97 -0.84,2.15 -1.69,4.22 -2.55,6.21 -0.26,-0.16 -0.52,-0.3 -0.79,-0.45 -1.89,-1 -3.94,-1.62 -6.07,-1.91 -4.24,-0.58 -8.43,0.03 -12.37,1.45 -4.37,1.57 -8.43,4.15 -11.92,7.21 -1.49,1.31 -3.65,3.4 -5.39,5.59 -1.31,1.65 -2.4,3.38 -2.84,4.96 -0.21,0.75 -0.33,1.51 -0.34,2.26 -0.02,0.78 0.07,1.52 0.26,2.21 0.28,1.02 0.77,1.95 1.46,2.74 0.66,0.75 1.51,1.37 2.53,1.8 3.44,1.45 7.2,-0.39 11.01,-2.24 1.75,-0.85 3.51,-1.71 5.07,-2.15 2.61,-0.73 5.2,-1.12 7.74,-1.19 -3.5,4.4 -7.51,8.27 -12.31,11.76 -5.88,4.25 -12.92,7.93 -21.63,11.3 -1.21,0.47 -2.53,0.96 -3.94,1.46 -1.37,0.49 -2.76,0.96 -4.19,1.43 h -0.01 v 0 c -0.73,0.24 -1.31,0.42 -1.9,0.61 -6.5199999,2.04 -15.02999994,4.7 -14.39999994,12.66 0.23,2.87 1.34000004,6.03 3.87000004,8.87 2.01,2.25 4.91,4.28 8.9699999,5.75 0.06,0.02 0.12,0.04 0.18,0.06 v 0 c 7.08,1.8 14.01,2.45 20.78,1.96 6.78,-0.48 13.39,-2.1 19.84,-4.8 7.66,-3.21 14.39,-7.28 20.28,-12.04 3.99,-3.22 7.58,-6.76 10.81,-10.57 -0.69,3.38 -1.73,6.7 -2.7,9.74 -1.7,5.38 -3.16,10.01 -2.34,13.49 0.04,0.17 0.1,0.37 0.17,0.58 0.78,2.31 2.65,3.65 4.82,4.28 1.87,0.54 4.02,0.51 5.75,0.1 0.28,-0.07 0.58,-0.15 0.88,-0.26 1.98,-0.67 3.84,-1.57 5.58,-2.66 6.330001,-3.98 10.820001,-10.46 13.120001,-17.7 2.29,-7.2 2.42,-15.17 0.04,-22.18 -0.72,-2.13 -1.69,-4.18 -2.89,-6.1 -1.74,-2.79 -3.95,-5.13 -6.6,-6.85 v 0 z"
        />
        <path
          style={{ fill: "#FCEB8F" }}
          d="m 85.865484,105.56 c 3.829679,-11.13 8.772257,-25.47 -4.451593,-35.7 4.342485,-1.41 8.172164,-1.67 11.478127,-1.06 4.735273,0.89 8.466752,3.64 11.041702,7.4 1.09107,1.59 1.96393,3.3 2.62949,5.1 2.23671,6.04 2.10578,12.96 -0.0655,19.21 -2.16034,6.22 -6.31734,11.76 -12.143689,15.11 -1.538418,0.89 -3.207766,1.62 -4.986221,2.18 -0.130929,0.04 -0.294591,0.08 -0.490984,0.13 -1.112899,0.24 -2.476745,0.26 -3.655107,-0.05 -0.85104,-0.22 -1.54933,-0.62 -1.767545,-1.22 -0.01092,-0.02 -0.02182,-0.07 -0.04364,-0.15 -0.56736,-2.2 0.829218,-6.25 2.454923,-10.95 v 0 z"
        />
        <path
          style={{ fill: "#FEDA00" }}
          d="m 85.521209,87.22 c -3.95,5.44 -8.58,10.4 -13.97,14.75 -5.61,4.53 -12.04,8.41 -19.37,11.49 -6.07,2.55 -12.28,4.06 -18.63,4.52 -6.34,0.45 -12.85,-0.15 -19.51,-1.85 -3.35,-1.23 -5.6999999,-2.87 -7.2999999,-4.65 -0.55,-0.62 -1.01,-1.26 -1.39,-1.9 14.8399999,1.79 28.6799999,-1.54 40.4299999,-7.65 14.1,-7.34 25.21,-18.71 31.46,-30.1 6.19,4.18 8.21,9.66 8.28,15.39 v 0 z"
        />
        <path
          style={{ fill: "#FDF2B4" }}
          d="m 73.181209,48.08 c 1.02,-2.31 2.01,-4.72 3,-7.24 1.11,-2.84 2.22,-5.88 3.35,-9.1 0.43,-1.21 0.89,-2.72 1.41,-4.42 2.87,-9.31 7.68,-24.93 15.83,-23.37 4.610001,0.88 6.590001,6.43 7.330001,12.7 0.81,6.84 0.13,14.49 -0.29,18.61 -0.57,5.6 -1.46,11.14 -2.72,16.55 -1.05,4.54 -2.360001,8.98 -3.930001,13.27 -1.01,-0.39 -2.06,-0.71 -3.17,-0.93 -4.12,-0.84 -8.86,-0.44 -14.14,1.64 -0.08,-1.37 -0.22,-2.75 -0.43,-4.12 -0.42,-2.74 -1.14,-5.45 -2.32,-7.96 -0.41,-0.88 -0.86,-1.69 -1.35,-2.45 -0.5,-0.78 -1.04,-1.5 -1.62,-2.16 -0.29,-0.35 -0.62,-0.69 -0.95,-1.02 v 0 z"
        />
        <path
          style={{ fill: "#FCEB8F" }}
          d="m 76.051209,66.33 c -3.6,-1.88 -7.42,-3.18 -11.39,-3.81 -4.86,-0.77 -9.92,-0.52 -15.04,0.92 -1.92,0.54 -3.82,1.47 -5.71,2.39 -3.03,1.48 -6.03,2.94 -7.85,2.17 -0.45,-0.19 -0.83,-0.47 -1.13,-0.81 -0.3,-0.34 -0.51,-0.75 -0.64,-1.21 -0.1,-0.36 -0.14,-0.74 -0.14,-1.13 0.01,-0.4 0.08,-0.84 0.21,-1.3 0.29,-1.03 1.13,-2.32 2.15,-3.62 1.57,-1.97 3.56,-3.89 4.93,-5.1 3.16,-2.78 6.8,-5.1 10.67,-6.49 3.39,-1.22 6.97,-1.74 10.57,-1.25 1.69,0.23 3.31,0.72 4.8,1.5 1.38,0.73 2.65,1.72 3.77,3 0.45,0.52 0.88,1.09 1.29,1.73 0.4,0.62 0.77,1.29 1.11,2.01 1.01,2.13 1.62,4.48 1.99,6.9 0.21,1.36 0.34,2.73 0.41,4.1 v 0 z"
        />
        <path
          style={{ fill: "#F5A800" }}
          d="m 3.9212091,105.52 c -0.03,-0.23 -0.06,-0.45 -0.07,-0.67 -0.39,-4.91 6.4699999,-7.06 11.7199999,-8.7 0.75,-0.23 1.47,-0.46 1.96,-0.62 v 0 c 1.47,-0.48 2.9,-0.97 4.27,-1.46 1.33,-0.47 2.68,-0.97 4.05,-1.51 9.04,-3.5 16.36,-7.34 22.48,-11.78 5.86,-4.25 10.61,-9.05 14.71,-14.64 0.35,0.04 0.7,0.09 1.04,0.15 3.46,0.55 6.82,1.68 10,3.33 -5.88,10.9 -16.51,21.85 -30.07,28.9 -11.57,6.02 -25.26,9.19 -39.9099999,7.02 -0.06,-0.01 -0.12,-0.01 -0.18,-0.02 v 0 z"
        />
      </g>
    </svg>
  ),
  spinner: (props: { className?: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  ),
  close: (props: { className?: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  ),
  user: ({ className, ...props }: React.ComponentProps<"svg">) => (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      className={`h-4 w-4 ${className}`}
      {...props}
    >
      <path d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z" />
    </svg>
  ),
  robot: ({ className, ...props }: React.ComponentProps<"svg">) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
      {...props}
    >
      <path d="M12 8V4H8" />
      <rect width="16" height="12" x="4" y="8" rx="2" />
      <path d="M2 14h2" />
      <path d="M20 14h2" />
      <path d="M15 13v2" />
      <path d="M9 13v2" />
    </svg>
  ),
  openAI: ({ className, ...props }: React.ComponentProps<"svg">) => (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={`h-4 w-4 ${className}`}
      {...props}
    >
      <title>OpenAI icon</title>
      <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
    </svg>
  ),
};
